<template>
    <div>
        <div class="register" :style="registerback">
            <div class="register-box mainwidth">
                <div class="register-con">
                    <div class="reg-title">
                        <p>找回密码</p>
                        <LeftSquareOutlined />
                    </div>
                    <div class="reg-form">
                        <a-form layout="vertical" :model="formRegister" @submit="handleSubmit" ref="ruleForm" :rules="rules">
                            <a-form-item>
                                <a-input v-model:value="formRegister.user" placeholder="请输入账号">
                                    <template #prefix><UserOutlined /></template>
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input v-model:value="formRegister.phone" placeholder="请输入手机号">
                                    <template #prefix><MobileOutlined /></template>
                                </a-input>
                            </a-form-item>
                           <div class="code-box">
                                <a-form-item>
                                    <a-input v-model:value="formRegister.vercode" placeholder="请输入短信验证码">
                                        <template #prefix><MessageOutlined /></template>
                                    </a-input>
                                </a-form-item>
                                <a-form-item>
                                    <a-button type="primary">
                                        获取短信
                                    </a-button>
                                </a-form-item>
                           </div>
                            <a-form-item>
                                <a-input v-model:value="formRegister.password" type="password" placeholder="请输入密码">
                                    <template #prefix><LockOutlined /></template>
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input v-model:value="formRegister.repassword" type="password" placeholder="请再次输入密码">
                                    <template #prefix><LockOutlined /></template>
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-button type="primary" html-type="submit">
                                    确认提交
                                </a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {LeftSquareOutlined,UserOutlined,LockOutlined,MobileOutlined,MessageOutlined} from '@ant-design/icons-vue';
    export default {
        name:'RetrievePassword',
        components:{
            LeftSquareOutlined,UserOutlined,LockOutlined,MobileOutlined,MessageOutlined
        },
        data() {
            return {
                registerback:{
                    backgroundImage: "url(" + require("../assets/images/register.jpg") + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                },
                formRegister: {
                    user: '',
                    password: '',
                    repassword:'',
                    phone:'',
                    vercode:'',
                },
            }
        },
        methods: {
            onSubmit() {
                this.$refs.ruleForm.validate()
                .then(() => {
                    console.log('values', this.formRegister);
                })
                .catch(error => {
                    console.log('error', error);
                });
            },
        },

    }
</script>
<style scoped lang="less">

/* 登录表单样式修改 */
.reg-form ::v-deep(.ant-form-item){
  margin-bottom: 12px;
}
.reg-form ::v-deep(.ant-input){
    padding: 2px 10px;
    border-left: 1px solid #fff;
    background-color:transparent;
    color: #fff;
}
.reg-form ::v-deep(.ant-input-prefix){
    color: #fff;
    margin-right: 10px;
}
.reg-form ::v-deep(.ant-input-affix-wrapper){
    background-color: rgba(0, 0, 0, .2);
    padding: 7px 11px;
}
.reg-form ::v-deep(.ant-input-affix-wrapper):hover{
    background-color: rgba(0, 0, 0, .4);
}
.ant-input-affix-wrapper:hover {
    border-color: #fff;
    border-right-width: 1px !important;
}
.ant-input-affix-wrapper:focus {
    border-color: #fff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
}
.ant-input-affix-wrapper-focused{
    border-color: #fff;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}
.reg-form ::v-deep(.ant-btn){
  width: 100%;
  padding:0 32px;
  color: #ffffff;
  margin-bottom: 10px;
  height: 42px;
  font-size:16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
    background: @color-blue;
}
.code-box{
    display: flex;
    justify-content: space-between;
}
.code-box::v-deep(.ant-btn){
    margin-bottom: 0;
    box-shadow: 0 0 0px rgba(0, 0, 0, .4);
}
.reg-form::v-deep(.ant-radio-wrapper){
    color: #fff;
    font-size: 16px;
}
.ant-radio-group{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.reg-form::v-deep(.ant-checkbox-wrapper){
    color: #fff;
    font-size: 14px;
}
.termssp{
    color: #0028bb;
    font-size: 14px;
}
</style>
<style scoped lang="less">
.register{
    height: 850px;
    display: flex;
    align-items: center;
}
.register-box{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .register-con{
        width:440px;
        height: 450px;
        padding: 15px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, .4);
        border:@border-ff;
        .reg-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom:1px solid rgba(255, 255, 255, .5);
            padding-bottom: 15px;
            p{
                font-size: @font-xl;
                color: #fff;
                margin-bottom: 0;
            }
            span{
                font-size: 24px;
                color: #fff;
            }
        }
        .reg-form{
            padding: 0 10px;
            padding-top: 15px;
        }
    }
}
</style>
